<template>
  <div ref="dropdownContainer" class="dropdown position-relative">
    <button
      ref="dropdownToggle"
      class="dropdown-toggle btn btn-sm w-100 text-start border-0 border-bottom rounded-0 text-transform-none"
      :class="{
        'bg-midnight-2 rounded-top border-transparent': dropdownMenuShown,
        'bg-transparent border-rhino rounded-0': !dropdownMenuShown,
      }"
      data-bs-toggle="dropdown"
      type="button"
      aria-expanded="false"
      data-cy="select-dropdown"
    >
      <slot>
        <!-- WARNING: using refs from setup here breaks opening and closing behaviour after first toggle, so please use CSS instead -->
        {{ buttonLabel }}
      </slot>
      <fa-icon
        :icon="faChevronDown"
        class="dropdown-toggle__icon position-absolute end-0 me-4 color-secondary"
        :class="{ 'mt-1': dropdownMenuShown }"
        :style="{
          transform: dropdownMenuShown ? 'rotate(180deg)' : 'rotate(0deg)',
        }"
      />
    </button>
    <div
      class="dropdown-menu w-100 rounded-0 rounded-bottom bg-midnight-2 border-0"
      style="margin-top: -0.5rem !important"
    >
      <hr class="mb-2 mt-0 mx-1 text-rhino" />
      <div
        v-for="item in toDisplayItems"
        :key="item.key"
        class="dropdown-item cursor-pointer text-secondary hover:text-white bg-transparent"
        :data-cy="item.key"
        :class="itemClass"
        @click="onItemSelected(item)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import useDropdown from '~/composables/bootstrap/useDropdown';
import type SelectItem from '~/components/form/dropdown/types';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    selectLabel: {
      type: String,
      default: '',
    },
    value: {
      type: [Object, String] as PropType<SelectItem | string>,
      default: null,
    },
    items: {
      type: Array as PropType<Array<SelectItem | string>>,
      required: false,
      default: () => [],
    },
    itemClass: {
      type: String,
      default: '',
    },
  },
  emits: ['change', 'dropdown-shown', 'dropdown-hidden'],
  setup(props, { emit }) {
    // value handling
    const internalValue = ref<SelectItem>(
      typeof props.value === 'string'
        ? { key: props.value, label: props.value }
        : props.value,
    );

    const assignItems = () =>
      props.items.map((item) => {
        if (typeof item === 'string') {
          return { key: item, label: item };
        }

        return item;
      });

    // data source handling
    const internalItems = ref<Array<SelectItem>>(assignItems());

    watch(
      () => props.items,
      () => {
        internalItems.value = assignItems();
      },
    );

    // convention-wise it is recommended to use computed with filtering instead of v-for and then v-if in the template
    const toDisplayItems = computed(() =>
      internalValue.value == null
        ? internalItems.value
        : internalItems.value.filter(
            (item) => internalValue.value.key !== item.key,
          ),
    );
    // bootstrap dropdown
    const dropdownToggle = ref<HTMLElement>(null);
    const dropdownContainer = ref<HTMLElement>(null);
    const { dropdownInstance, dropdownMenuShown } = useDropdown(
      dropdownToggle,
      dropdownContainer,
      {
        offset: [-0.5, 0],
        autoClose: true,
      },
      () => {
        emit('dropdown-shown');
      },
      () => {
        emit('dropdown-hidden');
      },
    );

    const onItemSelected = (entry) => {
      internalValue.value = entry;
      emit('change', entry);
      dropdownInstance.value.hide();
    };

    // ui handling
    const buttonLabel = computed<string>(() =>
      props.selectLabel != null && props.selectLabel.length > 0
        ? props.selectLabel
        : 'Select...',
    );

    const compontentId = useId();

    return {
      faChevronDown,

      buttonLabel,
      toDisplayItems,
      dropdownToggle,
      dropdownContainer,
      dropdownMenuShown,
      onItemSelected,
      compontentId,
    };
  },
});
</script>
